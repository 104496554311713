// $(document).on('click', 'a' ,function (e) {
//     e.preventDefault();
//     var _this = $(this);
//
//     if(_this.hasClass('secondary--dark')
//         || _this.hasClass('secondary')
//         || _this.hasClass('link-button')
//         || _this.hasClass('primary')
//         || _this.hasClass('nt-strip__signup-cta')
//         || _this.hasClass('nt-strip__login-cta')
//     ) {
//         $('#control-modal').prop('checked', true);
//     }
// })


// $(document).ready(function () {
//     $('a').attr('href', '/');
// })

